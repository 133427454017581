@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img {
    @apply inline-block;
  }
  :root {
    --background: 8 10% 98.5%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
  .dark {
    --sidebar-background: 220 14.3% 8%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}
@layer base {
  :root {
    --background: 8 10% 97.5%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 98.5%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 98%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 24 9.8% 10%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 60 4.8% 93%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 93%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 94%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 20 14.3% 4.1%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 220 14.3% 6%;
    --foreground: 220 9.1% 97.8%;
    --card: 220 14.3% 6%;
    --card-foreground: 220 9.1% 97.8%;
    --popover: 220 14.3% 8%;
    --popover-foreground: 220 9.1% 97.8%;
    --primary: 220 9.1% 97.8%;
    --primary-foreground: 220 9.8% 10%;
    --secondary: 220 6.5% 18%;
    --secondary-foreground: 220 9.1% 97.8%;
    --muted: 220 6.5% 11%;
    --muted-foreground: 220 5.4% 63.9%;
    --accent: 220 6.5% 10.1%;
    --accent-foreground: 220 9.1% 97.8%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 220 6.5% 15.1%;
    --input: 220 6.5% 15.1%;
    --ring: 24 5.7% 82.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.markdown img {
  max-height: 40vh;
}

hr {
  margin: 2rem 0;
}

html {
  transition: font-size 0.2s ease;
}

h1,
h2,
h3,
label {
  font-family: var(--font-jetbrains-mono);
}

pre {
  font-family: var(--font-jetbrains-mono);
}

.markdown-section pre {
  font-family: var(--font-jetbrains-mono);
  font-size: 0.875rem;
  @apply bg-muted rounded-md p-1 my-2;
}

.markdown-section h1 {
  @apply text-2xl font-bold  mb-2;
}

.markdown-section h2 {
  @apply text-xl font-bold  mb-2;
}

.markdown-section h3 {
  @apply text-lg font-bold  mb-2;
}

.markdown-section h4 {
  @apply text-base font-bold  mb-2;
}

.markdown-section h5 {
  @apply text-sm font-bold  mb-2;
}

.markdown-section h6 {
  @apply text-xs font-bold  mb-2;
}

.markdown-section p {
  @apply mb-4;
}

.markdown-section ul {
  @apply list-disc pl-4 mb-4;
}

.markdown-section ul li {
  @apply mb-2;
}

.markdown-section a {
  @apply text-blue-500 underline;
}

.markdown-section table {
  @apply w-full text-sm border-collapse border border-muted mb-6 rounded-lg overflow-hidden p-1;
}

.markdown-section th,
.markdown-section td {
  @apply p-2 text-left;
}

.markdown-section th {
  @apply bg-muted;
}

.markdown-section td {
  @apply border border-muted;
}

.markdown-section ol {
  @apply list-decimal pl-4 mb-6;
}

.codex-editor__redactor {
  padding-bottom: 0px !important;
}

.ce-toolbar__settings-btn {
  @apply text-foreground opacity-80 hover:text-black;
}
.ce-toolbar__plus {
  @apply text-foreground opacity-80 hover:text-black;
}


.ce-block--selected .ce-block__content {
 @apply bg-primary/20;
}

.ce-paragraph::selection {
  @apply bg-primary/20;
}

.ce-paragraph::selection {
  @apply bg-primary/20;
}

.message-container.x:last-child {
  min-height: 70dvh;
}